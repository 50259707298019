import React from 'react';

function ProjectsPage() {
    return (
        <div>
            <h1>Projects Page</h1>
            {/* Add your content here */}
        </div>
    );
}

export default ProjectsPage;