import React from 'react';
import { Link } from 'react-router-dom';

const ProjectCard = (props) => {
    const { thumbnail, title = 'Title', subtitle = 'Subtitle', content = 'Content', link, prototypeLink, image, primary = 'black', secondary = 'black', hideIcon = false } = props;
    return (
        <div className="project-card VStack md:HStack gap-4 md:gap-12">
            <div className="placeholder-image hover:-translate-y-2 hover:scale-105 transition-all in-expo">
                <img src={image} alt={title} />
            </div>
            <div className="flex flex-col gap-2.5 font-panel">
                {/* small thumbnail with maxwidth or height 38*/}
                {!hideIcon &&
                    <div className="thumbnail h-14">
                        <img className='h-full' src={thumbnail} alt={title} />
                    </div>
                }
                <h2 className={`text-2xl md:w-4/6 font-black text-${primary}`}>{title}</h2>
                <h3 className={`font-bold text-${secondary}`}>{subtitle}</h3>
                <h3 className="font-sans md:w-4/5">{content}</h3>
                <div className="HStack gap-2.5">
                    <Link to={link} rel="noopener noreferrer" className={`green-btn bg-${primary}`}>Read More</Link>
                    {prototypeLink && <Link to={prototypeLink} className='blue-btn' rel="noopener noreferrer">Try it</Link>}
                </div>
            </div>
        </div>
    );
};

export default ProjectCard;