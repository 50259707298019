import React from 'react';
import { Helmet } from 'react-helmet-async';

import Transition from '../../components/Transition';
import TableOfContents from '../../components/ToC';
import Highlighter from '../../components/Highlighter';
import Heading from '../../components/Heading';
import EmbedIt from '../../components/EmbedIt';
import LinkButton from '../../components/LinkButton';
import Quote from '../../components/Quote';
import Carousel from '../../components/Carousel';
import SEO from '../../components/SEO';

const MaatProject = () => {
    const toc = [
        "Overview",
        "Problem Statement",
        "Solution",
        "Process",
        "Analysis",
        "Design Thinking",
        "Reflection"
    ]
    return (
        <main className='p-0'>
            <SEO title="Design pitch for Ma'at inspired by Stephanie Posavec" description="Ma'at is a project conceptualized and designed for SI 616: Advanced Topics in Graphic Design and Communication where I had to create a brand identity inspired by a designer of our choice. I chose a designer whose artwork felt closer to me and with Stefanie Posavec as reference for the project, I created Ma'at. Ma'at was designed to be a challenge for merging Stephanie Posavec's design thinking with a completely new brand unrelated to any of her previous works." image="https://nikeplusdash-bucket.s3.us-east-2.amazonaws.com/preview/Maat-Web-Preview.png" />
            <div className=''>
                <TableOfContents contentsArray={toc} />
                <img src="https://nikeplusdash-bucket.s3.us-east-2.amazonaws.com/projects/maat/Maat-Hero.png" alt="Ma'at" className="hero-image xl:w-7/12  m-auto" />
                <div className='content VStack gap-2.5 ultrawide-container'>
                    <div id="overview" className="project-title section text-maat-primary py-4">Design pitch for Ma'at inspired by Stephanie Posavec</div>
                    <div className="project-subtitle">
                        <div className="detail-title">About Ma'at</div>
                        Ma'at, inspired by the Egyptian goddess of truth, balance, and order, offers premium health supplements designed to promote well-being through holistic harmony.
                        <br /><br />
                        <div className="detail-title">Project Overview</div>
                        Ma'at is a project conceptualized and designed for <Highlighter textColor="white" highlightColor="maat-primary" href="https://www.si.umich.edu/programs/courses/616">SI&nbsp;616:&nbsp;Advanced&nbsp;Topics in Graphic&nbsp;Design and Communication</Highlighter> where I had to create a brand identity inspired by a designer of our choice. I chose a designer whose artwork felt closer to me and with <Highlighter textColor="white" highlightColor="maat-primary" href="https://www.stefanieposavec.com/">Stefanie Posavec</Highlighter> as reference for the project, I created Ma'at. Ma'at was designed to be a challenge for merging Stephanie Posavec's design thinking with a completely new brand unrelated to any of her previous works.
                    </div>
                    <div className="details grid grid-cols-2 gap-4 md:HStack my-8">
                        <div className="detail-section VStack">
                            <div className="detail-title">Role</div>
                            <div className="detail-content">Graphic/UI Designer</div>
                        </div>
                        <div className="detail-section VStack">
                            <div className="detail-title">Timeline</div>
                            <div className="detail-content">Mar '24 to Apr '24</div>
                        </div>
                        <div className="detail-section VStack">
                            <div className="detail-title">Tools</div>
                            <div className="detail-content">
                                <div>Figma</div>
                                <div>Photoshop</div>
                                <div>Affinity Designer</div>
                                <div>Desmos</div>
                                <div>ChatGPT/Gemini</div>
                            </div>
                        </div>
                        <div className="detail-section VStack">
                            <div className="detail-title">Team</div>
                            <div className="detail-content VStack">
                                <div className="team-member">Yudi Chen (Mockups Designer)</div>
                                <div className="team-member">Sneha Hedge (Ideation)</div>
                            </div>
                        </div>
                    </div>

                    {/* How might we/I develop a design concept that embodies the principles of Maat, drawing on the distinctive style and philosophy of [Artist's Name], to create a cohesive and engaging brand experience? */}
                </div>
                <Heading id="problem-statement" text={"Problem Statement"} content={"How Might We synthesize the thematic elements found in the works of Stefanie Posavec to shape an original design concept, establishing a unique and resonant brand identity"} mode={5} primary={"maat-primary"} background={"white"} />

                <div className="content ultrawide-container gap-5 md:gap-8">
                    <div className="project-subheading section font-bold w-2/5" id="solution">Solution</div>
                    <div className="project-content">
                        <br />
                        <div className="detail-title">Design Philosophy</div>
                        Our approach to understanding Stefanie Posavec's design philosophy was to break down her works into their core elements and identify the thematic elements that could be used to create a unique brand identity. We identified the following key elements. Belog file goes in depth about her design styles and it was done by both me and Yudi, where we each studied all of her works and presented the key elements we found in her works.
                        <EmbedIt src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FRLnwJYZv61rtqr0STmLFkx%2FStefanie-Posavec---Design-Study%3Fpage-id%3D247%253A81%26type%3Ddesign%26node-id%3D880-302%26viewport%3D-61%252C156%252C0.06%26t%3DkIqXckSzaeFUaoWo-1%26scaling%3Dcontain%26starting-point-node-id%3D880%253A302%26show-proto-sidebar%3D1%26mode%3Ddesign" allowFullScreen title="Design Philosophy" aspectRatio={450 / 800} />

                        <div className="prototype flex gap-2">
                            <LinkButton href="https://www.figma.com/proto/RLnwJYZv61rtqr0STmLFkx/Stefanie-Posavec---Design-Study?page-id=344%3A122&type=design&node-id=812-384&viewport=443%2C115%2C0.1&t=CyxKLX510Pa4ualB-1&scaling=scale-down&starting-point-node-id=812%3A384&show-proto-sidebar=1&mode=design" textColor="white" background="maat-primary">
                                <img src="https://nikeplusdash-bucket.s3.us-east-2.amazonaws.com/thumbnails/Figma.png" className="project-image h-full" />
                                Preview Web
                            </LinkButton>
                            <LinkButton href="https://www.figma.com/proto/RLnwJYZv61rtqr0STmLFkx/Stefanie-Posavec---Design-Study?page-id=344%3A122&type=design&node-id=812-384&viewport=443%2C115%2C0.1&t=CyxKLX510Pa4ualB-1&scaling=scale-down&starting-point-node-id=812%3A384&show-proto-sidebar=1&mode=design" textColor="white" background="maat-primary">
                                <img src="https://nikeplusdash-bucket.s3.us-east-2.amazonaws.com/thumbnails/Figma.png" className="project-image h-full" />
                                Preview Mobile
                            </LinkButton>
                        </div>
                    </div>
                </div>
                <div className="content ultrawide-container bg-white text-black">
                    <div className='flex flex-col md:flex-row text-black gap-5 md:gap-8'>
                        <div id="process" className="project-subheading section font-bold w-2/5">How did i get here?</div>
                        <div className="project-content">
                            At first glance, Stefanie Posavec's designs seemed like art to me. However, upon deeper study, I discovered that they were actually carefully crafted data points that she infused with artistic flair. It was this unique combination that captured my interest in her designs. <br />
                            My background is in computer science and this analytical approach to design resonated with me and I wanted to know more about Stefanie and learn from her through this project. Another intriguing aspect of this project was that I was not expected to create a design that was similar to Stefanie's works. Instead, I was tasked with creating a brand that was inspired by her design philosophy, to quote "<Highlighter textColor="white" highlightColor="maat-primary" href="https://youtu.be/g4Hbz2jLxvQ?si=ynsacHbXrShHLYNP&t=89">Don't do it like me, do it like you 🥚</Highlighter>". So this is not a design Stefanie would make or I would make but it is a design that interplays with both my style and her thinking (as interpreted by me)<br />
                            <br /><br />
                        </div>
                    </div>
                    Initially many of our ideas were surrounding her original works such as exhibitions, museums, dance academy and even a travelling social platform. However, Sneha and I decided to explore non-conventional brand ideas and we came up with the concept of health supplements, specifically luxurious ones. Ma'at was born from this idea of having balance and equilibrium in our lives. Moving forward, we explored Stefanie's design elements, her ideology to create a cohesive brand.
                </div>
                <Heading id="analysis" text={"Know thy artist"} content={"Understanding the needs and frustrations of micro-businesses and freelancers"} mode={3} primary={"maat-primary"} background={"white"} />
                <div className="content ultrawide-container flex flex-col text-white gap-5 md:gap-8 bg-maat-secondary">
                    <div className="project-content">
                        <Quote color="white">
                            For the majority of my career, my projects centred around the visualisation of a specific dataset, where the work’s aesthetic and message are created through faithfully translating every data point into graphic elements and visual form.
                        </Quote>

                        Stefanie Posavec, a data artist, also know as "Poetic Data Designer" because of her innovative work in visualizing data and information design. She has created wearable, danceable, or hoppable, found in hospitals, museums, or on television, and often uses a human-scaled, hand-crafted design process.
                    </div>
                    <img src="https://nikeplusdash-bucket.s3.us-east-2.amazonaws.com/projects/maat/know-the-artist.png" alt="All of Stefanie's works" />

                    Her phenomenal works such as Dear Data, Writing Without Words, Passing Ships and Air Transformed:Better with Data Society give a deep insight into her deisgn thinking which involves turning words into visual data points using her past experiences. Interestingly, a lot of her works are analog and hand-drawn which is a stark contrast to the digital world we live in. Another interesting aspect is use of simple shapes and vivid colors to represent complex data points. This is what makes her works unique and stand out from the rest. <br />
                    {/* 
                    <div id="indexNav" class="index-item-navigation reveal-index-nav" data-content-field="navigation-indexNav">
                        <nav class="index-navigation text-center" role="navigation">
                    Maybe Insert a navigable index here
                        </nav>
                    </div> */}
                </div>
                <br />
                <br />
                <Heading id="design-thinking" text={"Design Thinking"} content={"Understanding the needs and frustrations of micro-businesses and freelancers"} mode={3} primary={"maat-primary"} background={"white"} />
                <div className="content ultrawide-container flex flex-col text-black bg-white gap-5 md:gap-8">
                    <div className="project-content">
                        <Carousel
                            aspectRatio={16 / 9}
                            time={4000}
                            data={
                                [
                                    "https://nikeplusdash-bucket.s3.us-east-2.amazonaws.com/projects/maat/Ideating-Logo.png",
                                    "https://nikeplusdash-bucket.s3.us-east-2.amazonaws.com/projects/maat/Mid-fi-logo.png",
                                    "https://nikeplusdash-bucket.s3.us-east-2.amazonaws.com/projects/maat/Final-Logo.png",
                                    "https://nikeplusdash-bucket.s3.us-east-2.amazonaws.com/projects/maat/Concept.png",
                                    "https://nikeplusdash-bucket.s3.us-east-2.amazonaws.com/projects/maat/Transforming-data.png",
                                    "https://nikeplusdash-bucket.s3.us-east-2.amazonaws.com/projects/maat/Packaging-concept.png",
                                    "https://nikeplusdash-bucket.s3.us-east-2.amazonaws.com/projects/maat/Final-Packaging.png",
                                    "https://nikeplusdash-bucket.s3.us-east-2.amazonaws.com/projects/maat/Maat-Poster.png",

                                ]
                            }
                        />
                        <div className='project-content my-10'>
                            Not having seen any work from Stefanie that involved Web Design or Logo Design, it was easier to use her key elements to construct a brand identity.

                            Starting with the logo, I ideated lots of ideas, firstly playing around symmetrically with the letters, thickness (because A repeats twice, I can have a twice as bolder than others). I then moved to exploring ambigrams, because our product focusses on balance. I went a notch up in complexity and tried to incorporate arabesque designs to match the Egyptian goddess theme but it was too complex for a luxury brand. I then moved back to original concept of playing around with letters, but I realised that the A in Ma'at can be represented as a leaf. This was a design concept which I carried forward, proposing 2 different logos for the brand. One being more comfortable and welcoming with its balanced leaves and carrying an essence of the products (leaves and natural substance) giving it an organic feeling. Other one was more business and modern, which would suit the luxury nature of the product<br /><br />

                            Next step was to develop a strong visual coherent with the brand identity we have formed. Using Gemini, I ideated different data points I could use such as process of consuming a pill from mouth the digestion journey visually presented, or a sleep graph or the types of incredients that go into our products. Using Gemini I conceptualized the 3 different products – the Dawn, the Dusk and the Zenith showing the daytime, nighttime and any-time products based on consumption. I used the two ideas of sleep and types of incredients to form a packaging based on the data. However an important concern was what kind of data to look for and what kind of transformation to apply. So I visited my memory lane to build something that reflects my though process; and viola <Highlighter textColor="white" highlightColor="maat-primary" href="https://www.jezzamon.com/fourier/">Fourier Transformation</Highlighter> gave me a visit. <br /><br />

                            Using a cortisol/stress level graph, I converted them to four sine waves using Fourier Transform (irony how it gave me so much stress), eventually dropping one to make it 3 and then I plot the sine waves on a polar graph to get different cardioids. I used those to create a simple n-sided polygon. Each polygon represented a different part of the curve such as peak, mid and bottom, representing day, noon and night stress levels respectively. <br /><br />

                            Following, I used moodboard to create a design palette, making sure the colors, day, night, all-day products are represented in the palette. I used the same palette to create a design guide for the products. Next plan was to make a design guide for products. I am inspired by designing for future so my goal was to make a design guide that is not only for the current products but also for the future products. I made a design legend with 4 categories to each with 3-4 options creating upto 20,000 unique combinations of products which can be scaled based on products. <br /><br />

                            Next steps involved me making a web prototype, mobile and different mockups such as watch and billboards for final presentation. <br /><br />

                            Lastly, I wanted to make a poster incorporating data and Ma'at. I am used to tracking my sleep using a Google Nest Hub which gives me a graph of my REM, Light and Deep Sleep. I used multiple common observations to develop an average sleep pattern. I used phases of moon to indicate sleep cycle, where waning indicates sinking into deep sleep and waxing means drawing towards light sleep. I superimposed both to contrast both the sides and represent a visual graph. If you have good sleep the visual would appear a symmetrical meaning both the sides are close to same.
                        </div>

                        <Carousel
                            aspectRatio={16 / 9}
                            time={4000}
                            data={
                                [
                                    "https://nikeplusdash-bucket.s3.us-east-2.amazonaws.com/projects/maat/Design-System.png",
                                    "https://nikeplusdash-bucket.s3.us-east-2.amazonaws.com/projects/maat/Design-System-II.png",
                                    "https://nikeplusdash-bucket.s3.us-east-2.amazonaws.com/projects/maat/Branding-Guideline.png",
                                    "https://nikeplusdash-bucket.s3.us-east-2.amazonaws.com/projects/maat/Branding-Guideline-1.png",
                                ]
                            }
                        />
                    </div>
                </div>
                <Heading id="reflection" text={"Reflection"} mode={3} primary={"maat-primary"} background={"white"} />
                <div className="content ultrawide-container flex flex-col bg-white text-black gap-5 md:gap-8">
                    I would like to describe my experience by these three key questions I would ask myself after each project:<br />

                    <div className="reflection flex flex-col gap-4">
                        <div className="reflection-content">
                            <div className="project-subheading font-bold">What went well?</div>
                            <div className="project-content py-1">
                                I learned A LOT, like really A LOT. From design thinking, ideating, to data visualizations in a non-traditional way, incorporating GPT to make complete concepts and filler content forming a coherent brand. I had chosen Stephanie Posavec to learn about her design thinking, I am certain I have benefited from such analytical approach to creativity, which I can use in future projects.
                                <br />

                                I have improved my workflow on how to draft faster and create effective prototypes using ChatGPT because while I could have thought of these, GPT helps speeden this up and allow for faster prototyping.
                                <br />

                                Another thing I have learnt during the project and course was design documenting and how it is necessary to document each product
                            </div>
                        </div>

                        <div className="reflection-content">
                            <div className="project-subheading font-bold"> What Could Be Improved?</div>
                            <div className="project-content py-1">
                                The project could have benefited a lot from ideation as conflicting time schedules made it hard to collaborate synchronously and ideate together. We ended up working asynchronously and division of work which allowed this project to grow but I feel like we could have done more if we had more time to ideate together.
                            </div>
                        </div>

                        <div className="reflection-content">
                            <div className="project-subheading font-bold"> What Possibilities Emerge?</div>
                            <div className="project-content py-1">
                                While this was a one-off project, I see the concepts I have learned in this to assist me working as a designer. I can use the design thinking to create more coherent and unique designs. I can use the data visualization techniques to create more engaging and informative designs. I can use the GPT to create more complete concepts! I am looking forward to using these techniques in future projects.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
};

export default Transition(MaatProject);