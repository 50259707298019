import { motion } from "framer-motion";
import ScrollToTop from "./ScrollToTop";

const Transition = (Child) => {
    let background = 'bg-black'
    // if (Child.name === 'MainPage') {
    //     background = 'bg-black'
    // }
    // else if (Child.name === 'MaatProject') {
    //     background = 'bg-maat-primary'
    // }
    // else if (Child.name === 'EduVerseProject') {
    //     background = 'bg-neon-green'
    // }
    // else if (Child.name === 'QuickenProject') {
    //     background = 'bg-quicken-primary'
    // }
    return () => (
        <>
            <Child />
            <ScrollToTop />
            {/* <motion.div
                className={"slide-in " + background}
                initial={{ scaleY: 0 }}
                animate={{ scaleY: 0 }}
                exit={{ scaleY: 1 }}
                transition={{ duration: 0.5, ease: [0.22, 1, 0.36, 1], delay: 0.8 }}
            />
            <motion.div
                className={"slide-out " + background}
                initial={{ scaleY: 1 }}
                animate={{ scaleY: 0 }}
                exit={{ scaleY: 0 }}
                transition={{ duration: 0.5, ease: [0.22, 1, 0.36, 1] }}
            /> */}
        </>
    );
};

export default Transition;