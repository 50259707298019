import React, { useEffect, useState } from 'react';

const TableOfContents = (props) => {
    let toc = props.contentsArray
    const [currentSection, setCurrentSection] = useState(toc[0])

    const updateElement = () => {
        let scrollPosition = window.scrollY
        if (scrollPosition == 0) {
            setCurrentSection(toc[0].split(' ').join('-').toLowerCase())
        }
        let sections = document.querySelectorAll('.section')
        sections.forEach(section => {
            if (section.offsetTop <= scrollPosition + 84) {
                setCurrentSection(section.id)
            }
        })
    }

    useEffect(() => {
        const interval = setInterval(() => { updateElement()}, 50)
        return () => clearInterval(interval)
    },[])
    return (
        <div id="toc" className="toc fixed ultrawide-container 2xl:w-[88%] sticky no-mobile text-right top-20 z-[11] px-6 text-xs opacity-50 mix-blend-exclusion">
            <div className={`vertical-line w-0.5 right-2 absolute`} style={{ height: toc.length * 18 + "px" }} />
            <ul className="toc-list flex flex-col items-end">
                {
                    toc.map((section, index) => <li key={index} className='w-fit'><a target='_self' href={`#${section.split(' ').join('-').toLowerCase()}`} className={`${currentSection == section.split(' ').join('-').toLowerCase()? "font-bold text-neon-green opacity-100":""} text-white hover:font-bold hover:text-neon-green hover:opacity-100`}>{section}</a></li>)
                }
            </ul>
        </div>
    );
};

export default TableOfContents;