import React from 'react';
import { Helmet } from 'react-helmet-async';

const SEO = (props) => {
    const { title, description, keywords, image } = props;

    return (
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={description} />
            <meta name='keywords' content={keywords} />
            <meta property="og:type" content="website" />

            <meta property="og:title" content={title} />
            <meta property="og:image" content={image} />
            <meta property="og:description" content={description} />

            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:creator" content="@nikeplusdash" />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:image" content={image} />
        </Helmet>
    )
};

export default SEO;