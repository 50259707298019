import React from 'react';
import { AnimatePresence } from 'framer-motion'
import { HelmetProvider } from 'react-helmet-async';
import { Routes, Route, useLocation } from 'react-router-dom';

import './App.css';

import Transition from './components/Transition';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import SEO from './components/SEO';

import AboutPage from './pages/AboutPage';
import ProjectsPage from './pages/ProjectsPage';
import ResumePage from './pages/ResumePage';
import MainPage from './pages/MainPage';

import QuickenProject from './pages/projects/Quicken';
import EduVerseProject from './pages/projects/EduVerse';
import MaatProject from './pages/projects/Maat';

function App() {
  const location = useLocation();

  return (
    <HelmetProvider>
      <SEO title="Nikesh Kumar's Portfolio" description="Nikesh Kumar is a designer exploring to harmonize the machine capabilities to enhance user performance and their experiences. Using his past experience in computer-science, he is currently interested in XR, haptics, and projections to create remarkable experiences." keywords="Nikesh Kumar, Nikesh, Kumar, designer, computer-science, XR, haptics, projections, experiences, user experience, user performance, machine capabilities, harmonize, remarkable experiences" image="https://nikeplusdash-bucket.s3.us-east-2.amazonaws.com/preview/Main-Page-Preview.png" />
      <title>Nikesh Kumar's Portfolio</title>
      <div className="App">
        <Navbar />
        {/* <AnimatePresence mode='wait'> */}
        <Routes location={location} key={location.pathname}>
          <Route path="/" exact element={<MainPage />} />
          {/* <Route path="/about" element={<AboutPage />} /> */}
          <Route path="/projects" element={<ProjectsPage />} />
          <Route path="/projects/quicken" element={<QuickenProject />} />
          <Route path="/projects/eduverse" element={<EduVerseProject />} />
          <Route path="/projects/maat" element={<MaatProject />} />
          {/* <Route path="/resume" element={<ResumePage />} /> */}
        </Routes>
        {/* </AnimatePresence> */}
        <Footer />
      </div>
    </HelmetProvider>
  );
}


export default App;
