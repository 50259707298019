
import React from 'react';

const Quote = (props) => {
    const { color = 'white' } = props;

    return (
        <div className={`quote block my-4 py-4 px-4 md:px-8 text-xl font-black font-panel border-l-4 border-${color} text-${color}`}>"{props.children}"</div>
    );
};

export default Quote;