import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <footer className="footer bg-black">
            <div className="content VStack ultrawide-container gap-8 flex h-full">
                <div className="text-white uppercase font-bold font-panel">Let's collaborate - get in touch</div>
                <div className="text-white md:w-1/5 grid grid-cols-2 gap-2">
                    {/* <Link to="/about" rel="noopener noreferrer">About Me</Link>
                    <Link to="/projects" rel="noopener noreferrer">Projects</Link> */}
                    <a href="mailto:nikeshk@umich.edu" target="_blank" rel="noopener noreferrer">Email</a>
                    <a href="https://behance.com/nikeshh" target="_blank" rel="noopener noreferrer">Behance</a>
                    <a href="https://github.com/nikeplusdash" target="_blank" rel="noopener noreferrer">GitHub</a>
                    <a href="https://www.linkedin.com/in/nikesh-kumar/" target="_blank" rel="noopener noreferrer">LinkedIn</a>
                </div>
            </div>
        </footer>
    );
};

export default Footer;