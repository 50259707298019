import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyCTLnbe3ild8w83WHKobCmcy5jXwB4OZik",
  authDomain: "nikeplusdash-portfolio.firebaseapp.com",
  projectId: "nikeplusdash-portfolio",
  storageBucket: "nikeplusdash-portfolio.appspot.com",
  messagingSenderId: "504370622119",
  appId: "1:504370622119:web:28672c42414fe3cf4b7582",
  measurementId: "G-0DWMKWBCEZ"
};

const app = initializeApp(firebaseConfig);

const analytics = getAnalytics(app);
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/*" element={<App />} />
      </Routes>
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
