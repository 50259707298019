import React from 'react';


function ResumePage() {
    return (
        <div>
            <h1>Resume Page</h1>
            {/* Add your resume content here */}
        </div>
    );
}

export default ResumePage;