import React from 'react';

const Highlighter = (props) => {
    let { href, highlightColor, textColor } = props;

    return (
        <>
            <i className='Generator-Tailwind-Classes text-white hover:text-white active:text-white bg-neon-green'></i>
            <a className={`highlight transition-all bg-${highlightColor} font-bold p-1 text-${textColor} hover:text-${textColor} active:text-${textColor} hover:px-2`} href={href} target="_blank" rel="noopener noreferrer">{props.children}</a>
        </>
    );
};

export default Highlighter;