import React from 'react';

const LinkButton = (props) => {
    let { href = '#', textColor = 'white', background = 'cherry-pink', target = '_blank' } = props;
    return (
        <>
            <i className='Generator-Tailwind-Classes text-black bg-white hover:text-black active:text-black'></i>
            <a href={href} className={`bg-${background} text-${textColor} text-sm md:text-base font-bold uppercase hover:-translate-y-1 hover:text-${textColor} hover:animate-none animate-pulse transition-all active:text-${textColor} gap-4 h-12 items-center flex w-fit py-2 px-4 border rounded`} target={target} rel="noopener noreferrer" >
                {props.children}
            </a>
        </>
    );
};

export default LinkButton;