import React from 'react';

const Heading = (props) => {
    const { mode, text, primary = "primary", background = "background", highlight = "highlight", content = "Null", dividerColor = "black", id} = props;
    let headingComponent;

    if (mode === 1) {
        headingComponent = (
            <div id className={`subheading justify-center h-74 VStack bg-${background} gap-2.5 text-2xl uppercase font-black font-panel relative z-10`}>
                <div className={`divider bg-${dividerColor}`}></div>
                <div className={`text ultrawide-container py-2 pad-web text-${primary}`}>{text}</div>
            </div>
        );
    } else if (mode === 2) {
        headingComponent = (
            <div id className={`subheading justify-center h-74 VStack bg-${highlight} gap-2.5 text-2xl uppercase font-black font-panel relative z-10`}>
                <div className={`text ultrawide-container py-2 pad-web text-${primary}`}>{text}</div>
            </div>
        );
    } else if (mode === 3) {
        headingComponent = (
            <div id={id} className={`subheading section justify-between VStack bg-${primary} gap-2.5 text-2xl uppercase font-black font-panel relative z-10`}>
                <div className={`divider bg-${dividerColor}`}></div>
                <div className={`text ultrawide-container py-2 pad-web text-${background}`}>{text}</div>
                <div className={`divider bg-${dividerColor}`}></div>
            </div>
        );
    } else if (mode === 4) {
        headingComponent = (
            <div id className="subheading justify-center h-74 VStack gap-2.5 text-2xl uppercase font-black font-panel relative z-10">
                <div className={`divider bg-${dividerColor}`}></div>
                <div className={`text ultrawide-container py-2 pad-web text-${primary}`}>{text}</div>
                <div className={`divider bg-${dividerColor}`}></div>
            </div>
        );
    } else if (mode === 5) {
        headingComponent = (
            <>
                <div className={`divider bg-${dividerColor}`}></div>
                <div id={id} className={`subheading section justify-between VStack bg-${primary} py-6 text-2xl uppercase font-black font-panel relative z-10`}>
                    <div className={`text ultrawide-container py-2 pad-web text-${background} text-base font-sans font-medium`}>{text}</div>
                    <div className={`content py-2 ultrawide-container normal-case text-${background} `}>{content}</div>
                </div>
                <div className={`divider bg-${dividerColor}`}></div>
            </>
        );
    } else {
        headingComponent = (
            <div className={`subheading justify-center h-74 VStack bg-${highlight} gap-2.5 text-2xl uppercase font-black font-panel relative z-10`}>
                <div className={`divider bg-${dividerColor}`}></div>
                <div className={`text ultrawide-container py-2 pad-web text-${primary}`}>{text}</div>
                <div className={`divider bg-${dividerColor}`}></div>
            </div>
        );
    }

    return headingComponent;

};

export default Heading;