import React, { useState, useEffect } from 'react';

const EmbedIt = (props) => {
    let { src, title, aspectRatio = 9/16, allowFullScreen = false } = props;
    const [width, setWidth] = useState('10');

    const onLoad = () => {
        setWidth((window.innerWidth > 0) ? window.innerWidth - 0.23 * window.innerWidth : 300)
    };

    useEffect(() => {
        onLoad();
    }, [])

    return (
        <div className='my-8 w-full z-25'>
            <iframe src={src} className='rounded-xl' width={width} height={parseInt(aspectRatio * width)} frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" title={title} allowFullScreen></iframe>
        </div>
    );
};

export default EmbedIt;