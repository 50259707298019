import React from 'react';
import { Helmet } from 'react-helmet-async';

import Heading from '../../components/Heading';
import Highlighter from '../../components/Highlighter';
import Quote from '../../components/Quote';
import LinkButton from '../../components/LinkButton';
import TableOfContents from '../../components/ToC';
import Transition from '../../components/Transition';
import SEO from '../../components/SEO';


function QuickenProject() {

    let toc = [
        "Overview",
        "Problem Statement",
        "Solution",
        "Process",
        "Interviews",
        "Survey",
        "Competitive Analysis",
        "Heuristic Analysis",
        "Usability Test",
        "Reflection",
    ]
    return (
        <main className=''>
            <SEO title="Improving Finance management with Quicken Simplifi – Nikesh Kumar" description="As part of my coursework in SI 622: Needs and Usability Evaluation, my team was assigned the task of conducting a detailed analysis of this niche market, the Quicken app and offering recommendations to enhance the user experience of Quicken Simplifi, as well as to develop targeted features to effectively reach this market segment." image="https://nikeplusdash-bucket.s3.us-east-2.amazonaws.com/preview/Quicken-Web-Preview.png" />
            <TableOfContents contentsArray={toc} />
            <div className='content VStack gap-2.5 ultrawide-container'>
                <img src="https://nikeplusdash-bucket.s3.us-east-2.amazonaws.com/projects/quicken/Quicken-Hero.png" alt="Quicken Simplifi" className="hero-image xl:w-8/12 m-auto" />
                <div id="overview" className="project-title section text-quicken-primary py-4">Improving Finance management with Quicken Simplifi</div>
                <div className="project-subtitle">
                    <div className="detail-title">About the Company</div>
                    Quicken Simplifi is a financial software designed for small businesses to effectively manage both personal and business finances. It also enables users to track hours, billables, and automatically organize their tax records. While there are alternative tools such as QuickBooks and Excel, they are often costly and complex or don’t distinguish among business and personal finances. Quicken Simplifi caters specifically to small businesses looking for an efficient and reliable financial management solution.
                    <br /><br />
                    <div className="detail-title">Project Overview</div>
                    Quicken Simplifi positions itself to compete with established industry leaders such as Quickbooks, Mint, and Freshbooks by targeting a market of freelancers at a competitive price point with both business and personal finance tool. <br /> <br />As part of my coursework in <Highlighter textColor="white" highlightColor="quicken-primary" href="https://www.si.umich.edu/programs/courses/622">SI&nbsp;622:&nbsp;Needs&nbsp;and&nbsp;Usability&nbsp;Evaluation</Highlighter>, my team was assigned the task of conducting a detailed analysis of this niche market, the Quicken app and offering recommendations to enhance the user experience of Quicken Simplifi, as well as to develop targeted features to effectively reach this market segment.
                </div>
                <div className="details grid grid-cols-2 gap-4 md:HStack my-8">
                    <div className="detail-section VStack">
                        <div className="detail-title">Role</div>
                        <div className="detail-content">UX Research Consultant</div>
                    </div>
                    <div className="detail-section VStack">
                        <div className="detail-title">Timeline</div>
                        <div className="detail-content">Jan '24 to Apr '24</div>
                    </div>
                    <div className="detail-section VStack">
                        <div className="detail-title">Tools</div>
                        <div className="detail-content">
                            <div>Figma</div>
                            <div>FigJam</div>
                            <div>Google Suite</div>
                        </div>
                    </div>
                    <div className="detail-section VStack">
                        <div className="detail-title">Team</div>
                        <div className="detail-content VStack">
                            <div className="team-member">Mo Ibrahim (Sr. Principal Product Designer)</div>
                            <div className="team-member">Tara Pugh (Sr. Product Manager)</div>
                            <div className="team-member">Aman Rawat</div>
                            <div className="team-member">Carl Fan</div>
                            <div className="team-member">Rushal Butala</div>
                            <div className="team-member">Shujun Li</div>
                        </div>
                    </div>
                </div>
            </div>
            <Heading id="problem-statement" text={"Problem Statement"} content={"How might We improve adoption of Quicken by microbusiness and freelancer community"} mode={5} primary={"quicken-primary"} background={"white"} highlight={"quicken-secondary"} />
            <div className="content ultrawide-container gap-5 md:gap-8">
                <div className="project-subheading section font-bold w-2/5" id="solution">Solution</div>

                <div className="project-content">My team conducted a series of tests and quantified the following recommendations to the client</div>
                <div className="split-pane md:flex py-6 gap-2.5">
                    <div className="recommendation-1-content text-lg flex py-8 flex-col gap-2 ">
                        <div className="solution-title font-black VStack font-panel text-quicken-primary">1 // Using easier language and reducing information overload</div>
                        <div className="divider bg-quicken-primary h-1"></div>
                        <div className="solution-content text-base">We suggest simplifying the language and streamlining the information in the app to enhance usability for users unfamiliar with terminology. This can be achieved by reorganizing the layout, incorporating whitespace, using color coding for income and expenses, and grouping related content logically to improve readability. A search bar can assist users in locating specific pages. Additionally, tool-tips can clarify complex features or unfamiliar language within the app. Our research participants expressed uncertainties about certain sections of the app. Furthermore, ensuring all elements of the Navigation Bar fit within the column, without the need for scrolling, aids users in accessing all page features, particularly on smaller screens.</div>
                    </div>
                    <img src='https://nikeplusdash-bucket.s3.us-east-2.amazonaws.com/projects/quicken/Recommendation-1.png' alt="Quicken 1st Recommendation" className="project-image md:w-3/5 max-h-[600px]" />
                </div>
                <div className="split-pane md:flex py-6 gap-2.5">
                    <div className="recommendation-1-content text-lg flex py-8 flex-col gap-2 ">
                        <div className="solution-title font-black VStack font-panel text-quicken-primary">2 // User-tuned features</div>
                        <div className="divider bg-quicken-primary h-1"></div>
                        <div className="solution-content text-base">In my post-survey analysis, I identified that users have a strong affinity for the platform, but there are specific aspects of the user interface that they do not foresee utilizing in the future. This reluctance is largely attributed to their existing usage of alternative platforms, which Quicken intends to incorporate into its product development strategy. To address this issue, we recommend implementing persona-driven features that allow users to select and prioritize the functionalities that align with their individual needs and preferences. One particular example is the Taxation feature, which received positive feedback, but faced challenges due to its placement within the platform.</div>
                    </div>
                    <img src='https://nikeplusdash-bucket.s3.us-east-2.amazonaws.com/projects/quicken/Recommendation-2.png' alt="Quicken 2nd Recommendation" className="project-image md:w-3/5 max-h-[600px]" />
                </div>
                <div className="split-pane md:flex py-6 gap-2.5">
                    <div className="recommendation-1-content text-lg flex py-8 flex-col gap-2 ">
                        <div className="solution-title font-black VStack font-panel text-quicken-primary">3 // Shortcuts for easy navigation</div>
                        <div className="divider bg-quicken-primary h-1"></div>
                        <div className="solution-content text-base">At times, our participants attempted to use a card function as a shortcut, only to be disappointed when it did not function as expected; other times they expected certain information as a part of Dashboard. Therefore, building upon the previous recommendation, it is crucial to provide individuals with the ability to personalize their dashboard and choose the specific shortcuts they prefer. This customization will facilitate seamless navigation between related screens and delineate different functionalities. The use of modular cards, will allow for more extensive customization with focus on necessary features and make the UI lot more navigable for individual customer</div>
                    </div>
                    <img src='https://nikeplusdash-bucket.s3.us-east-2.amazonaws.com/projects/quicken/Recommendation-3.png' alt="Quicken 3rd Recommendation" className="project-image md:w-3/5 max-h-[600px]" />
                </div>
            </div>
            <div className="content-backtrack bg-black">
                <div className="content ultrawide-container flex flex-col md:flex-row text-white gap-5 md:gap-8">
                    <div id="process" className="project-subheading section font-bold w-2/5">How did i get here?</div>
                    <div className="project-content">
                        <Highlighter highlightColor="quicken-primary" textColor="white" href="https://youtu.be/cv8aVMArKe4?si=HI1_DFkItnaLS_Lk&t=36">Let's start at the beginning one last time 🥚</Highlighter> <br /><br />
                        Tasked with improving Quicken App to attract customer base from freelancers, consultants and microbusiness, we worked our way up from interviewing microbusinesses, competitive analysis, heuristics study and collating all our findings based on the usability tests conducted  <br /><br />
                    </div>
                </div>
                <img src="https://nikeplusdash-bucket.s3.us-east-2.amazonaws.com/projects/quicken/Process.png" alt="UX Research Process" className="project-image xl:w-8/12 m-auto p-8" useMap='#process-map' />
                {/* <map id="process-map" name="process-map">
                    <area target="_self" alt="Interview" title="Interview" href="#interviews" coords="302,593,759,1279" shape="rect" />
                    <area target="_self" alt="Survey" title="Survey" href="#survey" coords="790,586,1160,1292" shape="rect" />
                    <area target="_self" alt="Competitive" title="Competitive" href="#competitve" coords="1221,575,1707,1286" shape="rect" />
                    <area target="_self" alt="Heuristic" title="Heuristic" href="#heuristic" coords="1735,563,2125,1292" shape="rect" />
                    <area target="_self" alt="Usability" title="Usability" href="#usability" coords="2184,552,2921,1295" shape="rect" />
                </map> */}
                <div className="content ultrawide-container flex flex-col text-white gap-5 md:gap-8">
                    <div className="project-subheading font-bold">Stakeholder Interview</div>
                    <div className="project-content">
                        We conducted interviews with stakeholders to understand the business goals and objectives of Quicken Simplifi. We also discussed the target audience, the current user base, and the features that are most popular among users and the feature. The insights from these interviews helped us understand the business requirements and align our design recommendations with the company's goals. Stakeholders had a particular interest in improving the application’s tax report generation and client management features.
                        <br /><br />
                        One of the key mention by the stakeholders was <Quote>We want to specifically target the users that use Excel and not Quickbooks, as we believe that they are the ones who are looking for a simpler solution to manage their finances</Quote>
                    </div>
                    <div className="project-subheading font-bold">Understanding the user</div>
                    <div className="project-content">
                        To understand the why's and how's of our product, let's delve into a user scenario of Elizabeth Warren who is a working businesswoman owning a toy shop in Kerrytown, Ann Arbor
                        <img src="https://nikeplusdash-bucket.s3.us-east-2.amazonaws.com/projects/quicken/User-Persona.png" alt="User Persona with Quote" className="project-image m-auto py-8 md:p-8" />
                        This is where Quicken Simplifi comes in. It's a financial management tool designed specifically to help people like Elizabeth. Quicken Simplifi boasts features that aim to streamline income and expense tracking for both personal and business finance, simplify invoicing, and offer reports for tax preparation<br />
                    </div>
                    <div className="project-subheading font-bold">What are we trying to answer</div>
                    <div className="project-content">
                        <ul className='unordered-list'>
                            {
                                [
                                    "How intuitive Quicken Simplifi’s interface design is in achieving users’ essential financial management tasks?",
                                    "What are the limits of the current tool that prevent users from achieving their goals?",
                                    "What are users’ confusions or concerns about using the tool?",
                                    "How can users better utilize Quicken Simplifi’s features?",
                                ].map((question, index) => <li key={index}>{question}</li>)
                            }
                        </ul>
                        <br />
                        By asking these questions, we will gather information to tailor recommendations for enhancing Quicken while gaining insight into user needs and frustrations with the current tool. This will enable us to effectively target the freelancer community and increase adoption rates.<br />
                    </div>
                </div>
                <Heading id="interviews" text={"Interview Process"} content={"Understanding the needs and frustrations of micro-businesses and freelancers"} mode={5} primary={"quicken-primary"} background={"white"} highlight={"quicken-secondary"} />
                <div className="content ultrawide-container flex flex-col text-white gap-5 md:gap-8">
                    <div className="project-content">
                        I started by scoping out local micro-businesses in my vicinity, finding potential interviewees that would help us carry out the study and get an understanding of their current needs and frustrations of the target users. My team also found out about small businesses/freelancers through their personal contacts. By reaching out to five local businesses that operate on micro scale, 2 of them replied back and agreed to be interviewed and remaining 3 came from direct contacts. We then developed a semi-structured interview protocol that centered on how micro-businesses and freelancers handle personal versus business transactions and monitor expenses for various projects, and how they manage taxes. Three interviews were conducted face-to-face, with two taking place at the interviewee's workplace and one at a location of their choosing on campus. The remaining two interviews were conducted via online Zoom calls. These interviews spanned a length of 20 minutes to 40 minutes with an average of 30.

                        <br /><br />

                        Below is a list of all participants who were interviewed and their respective businesses: <br /><br />
                        <div className="flex m-auto">
                            <table className="table-auto border-2">
                                <td className='px-6 py-8'>
                                    <b>Participant 1</b>: Owner of a local business shop in retail purchases in-person in Ann Arbor.<br />
                                    <b>Participant 2</b>: Owner of a local food joint with in-restaurant dining and take-out business in Ann Arbor.<br />
                                    <b>Participant 3</b>: Owner of a solar panel service company based in Ypsilanti<br />
                                    <b>Participant 4</b>: Son of a trucking business owner, who handles the business’ finances.<br />
                                    <b>Participant 5</b>: Student who is a branding and marketing freelancer<br />
                                </td>
                            </table>
                        </div>
                        <br />
                        After conducting the interviews, we analyzed the data using open-coding and affinity diagramming to identify common themes and patterns. We then synthesized the data to create personas and user stories that would guide the design recommendations. The insights from these interviews helped us understand the needs and frustrations of micro-businesses and freelancers, which we used to inform our design recommendations for Quicken Simplifi.

                        <img src="https://nikeplusdash-bucket.s3.us-east-2.amazonaws.com/projects/quicken/Interview-process-1.png" alt="Aggregating and Identifying themes from Interview process" />

                        The interviews gave us 3 main insights that I used to inform our design recommendations:<br /><br />
                        <ul className='unordered-list'>
                            {
                                [
                                    "Participants use a variety of digital platforms for taxation and other finance utilities",
                                    "Participants' preference for traditional/existing financial management practices driven by their habits and concern around the privacy and security of new third-party software",
                                    "Limited knowledges of finance and tax stop the target users from changing their financial management strategy with trust being a major limitation",
                                ].map((insight, index) => <li key={index}>{insight}</li>)
                            }
                        </ul>

                        An important and crucial theme that emerged from the interviews was the participants' preference for traditional/existing financial management practices driven by their habits and the trust they have built.

                        <Quote>
                            I just depend on my accountant, who is my friend's daughter and she manages everything and gives us a discount too
                        </Quote>

                        Although the quoted amount may be relatively higher than that provided by Quicken, many of our participants expressed confidence in their accountants' expertise and ability to maximize benefits. They trust their accountants to be knowledgeable and effective in managing their finances. This was communicated as a critical recommendation for long term success of Quicken Simplifi. However, our clients expressed that inclusion of accountants in the platform would be a challenge due to solo nature of the business and the cost associated with it. This is where we see an opportunity for Quicken Simplifi to bridge the gap and provide a platform for accountants to be included in the platform.
                    </div>
                </div>
                <Heading id="survey" text="Survey Design" content="Collecting multiple data points for supporting Interview findings" mode={5} primary="quicken-primary" background="white" highlight="quicken-secondary" />
                <div className="content ultrawide-container flex flex-col text-white gap-5 md:gap-8">
                    Our survey is designed to assess customer opinions on competing entities, pinpointing their challenges and preferences. By integrating this information with demographic insights, we can better understand our target demographic and craft detailed personas, reinforcing our findings from user interviews.
                    <LinkButton href="https://bit.ly/quicken-survey" textColor="black" background="white">
                        <img src="https://nikeplusdash-bucket.s3.us-east-2.amazonaws.com/thumbnails/qualtrics.svg" className="project-image h-full" />
                        Open Survey
                    </LinkButton>
                    <img src='https://nikeplusdash-bucket.s3.us-east-2.amazonaws.com/projects/quicken/Survey-Process.png' alt="Survey Takeaways" className="project-image m-auto md:w-3/5 py-8 md:p-8" />
                </div>
                <Heading id="competitive-analysis" text="Competitive Analysis" content="Divergent focus on competitors followed by convergent key insights" mode={5} primary="quicken-primary" background="white" highlight="quicken-secondary" />
                <div className="content ultrawide-container flex flex-col text-white gap-5 md:gap-8">
                    The competitive analysis is designed to assess customer opinions on competing entities, pinpointing their challenges and preferences. Some questions we sought to answer were:<br />
                    <ul className='unordered-list'>
                        {
                            [
                                "How does the user interface and ease of use of Quicken Simplifi compare to its competitors?",
                                "What distinct features does Quicken Simplifi offer that differentiate it in the market?",
                                "What are some features present in the competitors that Quicken Simplifi could adapt?",
                            ].map((question, index) => <li key={index}>{question}</li>)
                        }
                    </ul>
                    <div className="project-subheading font-bold">Criteria for Selecting Comparison Products</div>
                    <div className="project-content">
                        Firstly, I gathered a list of competitors through client meetings and Reddit/Forum analysis of <b>r/Freelancers</b> and <b>r/Copywriting</b>. Then I determined 3 criteria categories to effectively analysze which were basic information, user experience and features. A breakdown of each category is as follows:<br /><br />
                        <ul className='unordered-list'>
                            <li>Basic Information describe the pricing model, advertisements, target audience, and the platforms on which they are available. </li>
                            <li>User Experience:
                                <ul className='unordered-list circle mx-8'>
                                    <li>Design Aesthetics: indicate the design choices made by each competitor and how they affect the perception and utility. This varies from color schematics to component placement on the page/app</li>
                                    <li>Customer Support: evaluates each product on the support/feedback provided to rescue user from an undesirable state</li>
                                    <li>Onboarding Guide Clarity: classifies the ease of access for beginner app users through onboarding guides.</li>
                                    <li>Redundancy of Features: explores how different apps implement redundancy, i.e., accessing the same goal through 2 various means, like making a client in the client tab or making a new client while making a project.</li>
                                    <li>Customizability: is allowing users to customize the platform according to their preferences and design choices.</li>
                                </ul>
                            </li>
                            <li>Features: focuses on specific features that our clients wish to focus</li>
                        </ul>
                    </div>

                    <img src='https://nikeplusdash-bucket.s3.us-east-2.amazonaws.com/projects/quicken/Competitive-Analysis.png' alt="Competitive Analysis" className="project-image m-auto md:w-3/5 md:p-8" />

                    The competitive analysis helped us identify key features that Quicken Simplifi could adopt to improve its user experience and differentiate itself in the market. We also gained insights into the strengths and weaknesses of Quicken Simplifi compared to its competitors, which will inform our design recommendations for the app.

                    <ul className='unordered-list'>
                        {
                            [
                                "Seamless and Distinct Dashboard: through use of shortcuts and compact design and language",
                                "Integration Capabilities: with other financial management tools and platforms",
                                "Features assisting Taxation:offering auto generated reports and option to invite consultant, thus addressing the 'trust' issue of users",
                            ].map((insight, index) => <li key={index}>{insight}</li>)
                        }
                    </ul>

                    <LinkButton href="https://bit.ly/quicken-competitive-analysis" textColor="black" background="white">
                        <img src="https://nikeplusdash-bucket.s3.us-east-2.amazonaws.com/thumbnails/google-sheets.svg" className="project-image h-full" />
                        Competitive Analysis
                    </LinkButton>
                </div>
                <Heading id="heuristic-analysis" text="Heuristic Analysis" content="Identifying design problems in the user interface using a set of guidelines with a group of evaluators" mode={5} primary="quicken-primary" background="white" highlight="quicken-secondary" />
                <div className="content ultrawide-container flex flex-col text-white gap-5 md:gap-8">
                    <img src='https://nikeplusdash-bucket.s3.us-east-2.amazonaws.com/projects/quicken/Heuristic-Analysis.png' alt="Heuristic Analysis" className="project-image m-auto md:w-4/5" />

                    I reviewed 9 out of 10 heuristics for applicability to our study, determining that the last one was less relevant due to Quicken being a beta product. The color coding system signifies Quicken's compliance with each heuristic - red indicating severe noncompliance, yellow indicating areas requiring attention but not immediately urgent, and green indicating optimal compliance. Subsequently, I organized user flows to assess heuristics based on specific tasks.

                    <ul className='unordered-list'>
                        {
                            [
                                "Create an account and onboard by adding accounts to analyze expenditure income.",
                                "Add a custom transaction involving cash, identify recurring expenditures, and create spending plans to manage them.",
                                "Refer to reports/graphs to identify high-expenditure groups and set savings goals based on new expenses. Develop a goal to save a certain amount of money."
                            ].map((task, index) => <li key={index}>{task}</li>)
                        }
                    </ul>

                    The process of heuristics evaluation along with identified issues has been described below along:<br />
                    <ul className='unordered-list'>
                        {
                            [
                                "Inconsistent Design & Interaction",
                                "Absence of Shortcuts for experienced users",
                                "Lack of User Feedback",
                                "Duplicity of functions",
                            ].map((task, index) => <li key={index}>{task}</li>)
                        }
                    </ul>
                    <LinkButton href="https://bit.ly/quicken-heuristic-analysis" textColor="black" background="white">
                        <img src="https://nikeplusdash-bucket.s3.us-east-2.amazonaws.com/thumbnails/google-sheets.svg" className="project-image h-full" />
                        Heuristic Analysis
                    </LinkButton>
                </div>
                <Heading id="usability-test" text="Usability Testing" content="Designing user tasks to assess efficiency and limitations of Quicken Simplifi and recommend areas of concern and improvement" mode={5} primary="quicken-primary" background="white" highlight="quicken-secondary" />
                <div className="content ultrawide-container flex flex-col text-white gap-5 md:gap-8">
                    After analyzing the results of 6 usability interviews, a consistent pattern emerged in the process:
                    <img src='https://nikeplusdash-bucket.s3.us-east-2.amazonaws.com/projects/quicken/Usability-Test-Findings.png' alt="Usability Test Findings" className="project-image m-auto md:w-4/5" />
                    <LinkButton href="#solution" textColor="black" background="white" target="_self">
                        <img src="https://nikeplusdash-bucket.s3.us-east-2.amazonaws.com/thumbnails/scroll-animation.gif" className="project-image h-full scale-150 rotate-180" />
                        Check Solutions
                    </LinkButton>
                </div>
            </div>
            <Heading id="reflection" text={"Reflections and Lessons Learnt"} mode={3} primary={"quicken-primary"} background={"white"} highlight={"quicken-secondary"} />
            <div className="content ultrawide-container gap-5 md:gap-8">
                I would like to describe my experience by these three key questions I would ask myself after each project:<br /><br />

                <div className="reflection flex flex-col gap-4">
                    <div className="reflection-content">
                        <div className="project-subheading font-bold">What went well?</div>
                        <div className="project-content py-1">
                            During the initial stages of the project, I had concerns about the effectiveness of a team comprising solely UX Researchers, as teams typically benefit from a mix of skill sets. However, I was pleasantly surprised by the team's efficiency and productivity. Collaboratively, we were able to address any gaps in expertise, resulting in a cohesive and thorough report for the client. I was also greatly aided by Shujun Li who had prior experience in conducting usability tests. This improve my own skills in recruiting, structuring tasks and conducting usability tests. Throughout the project, I adapted my approach to organizing information by drawing from various resources, including classes and online materials, to produce well-structured reports for the client. I also learned how things work concurrently in a team environment, which was a valuable experience for me.

                            Lastly, I was able to learn a lot about the financial management industry and the needs of micro-businesses and freelancers. This was a great learning experience for me and I am grateful for the opportunity to work on this project.
                        </div>
                    </div>

                    <div className="reflection-content">
                        <div className="project-subheading font-bold"> What Could Be Improved?</div>
                        <div className="project-content py-1">
                            A lot of our tasks were reliant on recruitment of participants and we had to rely on our personal contacts to get the interviews done. This was a major bottleneck in our process and we could have improved by reaching out to more people and getting more interviews done. This limited our interview audience to people we knew – students, professors and local business owners. While we did receive valuable insights from these interviews, we could have benefitted from a more diverse audience.

                            Lastly, a lot of the features we conducted usaility tests on were under development, mmaking it difficult to separate cosmetic issues from functional ones. This made it difficult to identify the root cause of the issues and provide recommendations to the client. In the future, I would recommend conducting usability tests on a more stable version of the product to ensure that the feedback is relevant and actionable.
                        </div>
                    </div>

                    <div className="reflection-content">
                        <div className="project-subheading font-bold"> What Possibilities Emerge?</div>
                        <div className="project-content py-1">
                            This project has opened up a lot of possibilities for me in the field of UX Research. I have gained valuable experience in conducting interviews, usability tests, and heuristic evaluations. I have also learned how to synthesize data and present it in a clear and concise manner. This project has given me a good understanding of the financial management industry and applying UX principles to improve user experience at industry level. I am excited to continue working in this field and applying what I have learned to future projects.
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}

export default Transition(QuickenProject);